<template>
  <div class="container">
    <div class="list flex">
      <div v-for="(item, i) in arr" :key="i" class="item" @click="toWant(i)">
        <div class="img-wrap flex flex-align-center flex-pack-center">
          <img v-if="i<2" :class="'img-' + (i+ 1)" :src="item.img" />
          <img v-else :class="'img-' + (i+ 1)" :src="item.img1" />
        </div>
        <div class="name">{{item.name}}</div>
        <div v-if="i>1" class="name1">{{item.name1}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      arr: [
        {
          img: require("../../../assets/images/sale/mall/tcndxq_img1-6.png"),
          img1: require("../../../assets/images/sale/mall/tcndxq_img1_gray-6.png"),
          name: "加工需求",
          name1: "待开放"
        },
        {
          img: require("../../../assets/images/sale/mall/tcndxq_img2-6.png"),
          img1: require("../../../assets/images/sale/mall/tcndxq_img2_gray-6.png"),
          name: "找设计师改款",
          name1: "待开放"
        },
        {
          img: require("../../../assets/images/sale/mall/tcndxq_img3-6.png"),
          img1: require("../../../assets/images/sale/mall/tcndxq_img3_gray-6.png"),
          name: "找工厂定制",
          name1: "待开放"
        },
        {
          img: require("../../../assets/images/sale/mall/tcndxq_img4-6.png"),
          img1: require("../../../assets/images/sale/mall/tcndxq_img4_gray-6.png"),
          name: "打样",
          name1: "待开放"
        },
        {
          img: require("../../../assets/images/sale/mall/tcndxq_img5-6.png"),
          img1: require("../../../assets/images/sale/mall/tcndxq_img5_gray-6.png"),
          name: "实地待考察",
          name1: "待开放"
        },
        {
          img: require("../../../assets/images/sale/mall/tcndxq_img6-6.png"),
          img1: require("../../../assets/images/sale/mall/tcndxq_img6_gray-6.png"),
          name: "待质量和货期检测",
          name1: "待开放"
        }
      ]
    };
  },
  methods:{
    toWant(type) {
      if (type<2) {
        this.$router.push(`/want/wantIssue`);
      }
      // if (type==0) {
      //   this.$router.push(`/want/wantIssue?type=peitao`);
      // } else if (type==1) {
      //   this.$router.push(`/want/wantIssue?type=gaikuang`);
      // }
    },
  },
};
</script>

<style scoped lang="less">
.container {
  width: 1200px;
  margin: 0 auto;
  .list {
    padding: 30px;
    background-color: #fff;
    .item {
      width: 180px;
      height: 180px;
      border: 1px solid #a9b6e1;
      box-shadow: 0 3px 6px #dbe1f6;
      margin-left: 12px;
      position: relative;
      cursor: pointer;
      &:hover {
        border-color: #24B1EB;
        background-color: #f8f9ff;
      }
      &:first-child {
        margin-left: 0;
      }
      .img-wrap {
        height: 140px;
        img {
          &.img-1 {
            width: 128px;
            height: 104px;
          }
          &.img-2 {
            width: 148px;
            height: 98px;
          }
          &.img-3 {
            width: 112px;
            height: 92px;
          }
          &.img-4 {
            width: 141px;
            height: 80px;
          }
          &.img-5 {
            width: 152px;
            height: 96px;
          }
          &.img-6 {
            width: 124px;
            height: 100px;
          }
        }
      }
      .name {
        font-size: 16px;
        font-weight: bold;
        color: #292929;
        text-align: center;
      }
      .name1 {
        font-size: 14px;
        font-weight: 600;
        color: #292929;
        line-height: 20px;
        text-align: center;
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 21px);
      }
    }
  }
}
</style>