<template>
  <div>
    <div class="search-bar">
      <div class="flex flex-align-center">
        <div style="font-size: 18px; color: rgba(41, 41, 41, 1)">
          <b>南康集市</b>
        </div>
        <el-input
          v-model="query.key"
          style="max-width: 500px; margin-left: 50px"
          @change="search"
          placeholder="请输入商品名称"
          suffix-icon="el-icon-search"
        />
      </div>
    </div>
    <div
      class="menu"
      style="min-width: 1200px; background: #fff; border-bottom: 2px solid #eee"
    >
      <div class="container flex">
        <div class="cat-item">
          <span>产品类目</span>
          <div class="cat-menu" :class="{ active: catId }">
            <div v-for="(cat, i) in cats" :key="i" class="cat-menu-item">
              <h3 @click="goCat(cat)" :class="{ active: catId == cat.key }">
                {{ cat.title }}
              </h3>
              <div
                v-if="cat.children && cat.children.length > 0"
                class="item-list flex"
              >
                <span
                  @click="goCat(item)"
                  v-for="(item, j) in cat.children.slice(0, 3)"
                  :key="j"
                  :class="{ active: catId == item.key }"
                  >{{ item.title }}</span
                >
              </div>
              <div class="cat-sub-menu">
                <h4 @click="goCat(cat)" :class="{ active: catId == cat.key }">
                  {{ cat.title }}
                </h4>
                <div class="flex">
                  <span
                    @click="goCat(item)"
                    v-for="(item, j) in cat.children"
                    :key="j"
                    :class="{ active: catId == item.key }"
                    >{{ item.title }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-menu
          mode="horizontal" :selectable="false"
          style="border-bottom: none !important;font-weight:500;color:#4D4D4D;"
        >
          <a-menu-item :class="{'ant-menu-item-selected':routePath=='/sale/mall/home/design'}" key="sjs" @click="goto('/sale/mall/home/design')">设计师合作款</a-menu-item>
          <a-menu-item :class="{'ant-menu-item-selected':routePath=='/sale/mall/home/factory'}" key="gc" @click="goto('/sale/mall/home/factory')">工厂款</a-menu-item>
        </a-menu>
      </div>
    </div>
    <!-- 轮播大图 -->
    <el-carousel
      :interval="8000"
      arrow="never"
      :height="bannerHeight + 'px'"
      indicator-position="none"
    >
      <el-carousel-item v-for="(img, index) in imgs" :key="index">
        <img ref="bannerHeight" :src="img" width="100%" @load="imgLoad" />
      </el-carousel-item>
    </el-carousel>
    <!-- 新设计 -->
    <div class="block">
      <div class="title">
        <div class="zh">新设计</div>
        <div class="en">NEW DESIGN</div>
      </div>
      <div style="margin-top: 30px">
        <Comp1
          v-if="
            true ||
            (datas && datas.newDesignItems && datas.newDesignItems.length > 0)
          "
          :comp="datas.newDesignItems"
        ></Comp1>
      </div>
    </div>
    <div class="block">
      <div class="title">
        <div class="zh">热销款</div>
        <div class="en">HOT SALE</div>
        <!-- <div class="more" @click="goto('/sale/mall/search')">
          更多
          <span class="icon iconfont icon-youshuangjiantou"></span>
        </div>-->
      </div>
      <div style="margin-top: 30px">
        <Comp2
          v-if="datas.hotSaleItems && datas.hotSaleItems.length > 0"
          :comp="datas.hotSaleItems"
        ></Comp2>
      </div>
    </div>
    <div class="block">
      <div class="title">
        <div class="zh">提出您的需求</div>
        <div class="en">PRESENT YOUR NEEDS</div>
        <!-- <div class="more" @click="goto('/sale/mall/search')">
          更多
          <span class="icon iconfont icon-youshuangjiantou"></span>
        </div>-->
      </div>
      <div style="margin-top: 30px">
        <Comp3
          v-if="
            true ||
            (datas && datas.desigXhItems && datas.desigXhItems.length > 0)
          "
          :comp="datas.desigXhItems"
        ></Comp3>
      </div>
    </div>
    <div class="block">
      <div class="title">
        <div class="zh">更多热门工厂款</div>
        <div class="en">MORE POPULAR FACTORY MODELS</div>
      </div>
      <div style="margin-top: 30px">
        <Comp4
          v-if="datas.moreFactorys && datas.moreFactorys.length > 0"
          :comp="datas.moreFactorys"
        ></Comp4>
      </div>
    </div>
    <div class="block" style="margin-bottom: 50px">
      <div class="title">
        <div class="zh">设计工作室介绍</div>
        <div class="en">DESIGN STUDIO INTRODUTION</div>
      </div>
      <div style="margin-top: 30px">
        <Comp5></Comp5>
      </div>
    </div>
    <!-- 右侧提示栏 -->
    <FloatPanel></FloatPanel>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import { getIndex } from "@/service/mall";
import Comp1 from "./Comp1.vue";
import Comp2 from "./Comp2.vue";
import Comp3 from "./Comp3.vue";
import Comp4 from "./Comp4.vue";
import Comp5 from "./Comp5.vue";
// import Footer from "@/views/layout/Footer.vue";
import FloatPanel from "./Panel.vue";
import { getCats } from "@/service/item";
import { fetchTree } from "@/util";
export default {
  components: {
    Comp1,
    Comp2,
    Comp3,
    Comp4,
    Comp5,
    // Footer
    FloatPanel,
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
  },
  data() {
    return {
      query: {
        key: null,
        type: null,
      },
      cats: [],
      catId: null,
      imgs: [
        require("@/assets/images/sale/mall/mall_banner.jpg"),
        require("@/assets/images/sale/mall/mall_banner.jpg"),
      ],
      bannerHeight: "",
      datas: {
        // 新设计
        newDesignItems: [],
        // 热销款
        hotSaleItems: [],
        // 设计师现货款
        desigXhItems: [],
        // 更多热门工厂款
        moreFactorys: [],
      },
    };
  },
  mounted() {
    this.imgLoad();
    window.addEventListener(
      "resize",
      () => {
        if (this.$refs.bannerHeight[0])
          this.bannerHeight = this.$refs.bannerHeight[0].height;
        this.imgLoad();
      },
      false
    );
  },
  async created() {
    this.datas = await getIndex();
    getCats().then((rst) => {
      this.cats = fetchTree(rst, null, {
        name: { dest: "title" },
        id: { dest: "key" },
        parent: "parentId",
      });
    });
  },
  methods: {
    goCat(item) {
      // console.log(item);
      this.catId = item.key;
      this.$router.push("/sale/mall/home/factory?catId=" + item.key);
    },
    imgLoad() {
      this.$nextTick(() => {
        if (this.$refs.bannerHeight[0])
          this.bannerHeight = this.$refs.bannerHeight[0].height;
      });
    },
    goto(path) {
      this.menus = null;
      this.$router.push(path);
    },
    search() {
      this.$router.push("/sale/mall/home/factory?search=" + this.query.key);
    },
  },
};
</script>
<style scoped>
.block {
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
}

.title {
  font-weight: bold;
  text-align: center;
  position: relative;
}

.title .zh {
  font-size: 36px;
  color: rgba(41, 41, 41, 1);
}

.title .en {
  font-size: 24px;
  color: #d1d1de;
}

.title .more {
  position: absolute;
  right: 0;
  top: 20px;
  cursor: pointer;
}
</style>
<style lang="less" scoped>
.container {
  width: 1200px;
  margin: 0 auto;
}
.banner {
  height: 480px;
  background: url("../../../assets/images/sale/mall/mall_banner.jpg") no-repeat;
  background-size: auto 100%;
}
</style>
<style scoped>
.search-bar {
  min-width: 1200px;
  background: #fff;
  padding: 30px 0;
  border-bottom: 2px solid #eee;
}
.search-bar > div {
  width: 1200px;
  margin: 0 auto;
}
.search-bar > div >>> .el-select .el-input {
  width: 130px;
}
div.menu >>> .ant-menu-vertical .ant-menu-submenu {
  overflow-y: auto;
  height: 300px;
}
div.menu >>> .ant-menu-horizontal > .ant-menu-item {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
}
</style>
<style lang="less" scoped>
.container {
  width: 1200px;
  margin: 0 auto;
}
.cat-item {
  width: 200px;
  height: 60px;
  background-color: #fff;
  color: #292929;
  line-height: 60px;
  text-align: center;
  position: relative;
  font-weight: bold;
  background: rgba(240, 243, 255, 1);
  cursor: pointer;
  font-size: 16px;
  &.active {
    display: none;
  }
  &:hover {
    .cat-menu {
      display: block;
    }
  }
  .cat-menu {
    display: none;
    position: absolute;
    width: 200px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(169, 182, 225, 1);
    line-height: 1;
    font-size: 12px;
    z-index: 100;
    .cat-menu-item {
      padding: 10px 20px;
      text-align: left;
      position: relative;
      &:hover {
        background: rgba(204, 204, 204, 0.2);
        .cat-sub-menu {
          font-weight: 400;
          display: block;
        }
      }
      h3 {
        color: #292929;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 14px;
        &.active {
          color: #24B1EB;
        }
      }
      > .item-list {
        color: #4d4d4d;
        span {
          margin-right: 10px;
          margin-bottom: 10px;
          &.active {
            color: #24B1EB;
          }
          &:hover {
            color: #24B1EB;
          }
        }
      }
      .cat-sub-menu {
        display: none;
        width: 306px;
        // height: 112px;
        background: #fff;
        box-shadow: 0px 2px 4px 0px rgba(169, 182, 225, 1);
        position: absolute;
        right: -306px;
        top: 0;
        color: #4d4d4d;
        padding: 20px;
        h4 {
          font-size: 14px;
          margin-bottom: 12px;
          font-weight: bold;
          &.active {
            color: #24B1EB;
          }
        }
        > div {
          span {
            margin-right: 10px;
            margin-bottom: 10px;
            &.active {
              color: #24B1EB;
            }
            &:hover {
              color: #24B1EB;
            }
          }
        }
      }
    }
  }
}
</style>